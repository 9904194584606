import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { callPopUpEdit } from '../../../../redux/actions/addOns/voip/voipActions'
import NotificationsListingWrapper from '../../notification/NotificationsListingWrapper'
import { patientWhatsAppLogsLoad, whatsAppAccountLoad, whatsAppEdit, whatsAppNotificationLoad, whatsAppNotificationUpdate, whatsAppPatientLoad, whatsAppProfileLoad } from '../../../../redux/actions/addOns/whatsApp/whatsAppActions'
import WhatsAppAdvertisement from './WhatsAppAdvertisement'
import { notificationUpdate } from '../../../../itrust_common/redux/actions/notificationActions'

function WhatsAppNotificationList(props) {
  const { whatsAppNotifications, saving, storeWhatsAppNumber, callPopUpStatus, organisationId, totalRecords, unread_count, whatsAppConfig } = props
  const dispatch = useDispatch()

  const handleWhatsAppNotificationLoad = (filters) => {
    dispatch(whatsAppNotificationLoad({ activity_type: 'whatsapp', ...filters, include: ['activity'] }))
  }

  const handleWhatsAppPatientView = async (patientId, from) => {
    let filters = { sort: "send_at", direction: 'asc', rpp: 999999999, search: "", page: 1, filter: {} }
    callPopUpStatus && dispatch(callPopUpEdit(false))

    if (from) {
      await dispatch(patientWhatsAppLogsLoad({ ...filters, direction: 'asc', from: from }))
    }
    else {
      await dispatch(whatsAppPatientLoad(patientId))
      await dispatch(patientWhatsAppLogsLoad({ ...filters, direction: 'asc', patient_id: patientId }))
    }
    if (!storeWhatsAppNumber) {
      await dispatch(whatsAppProfileLoad({ store_id: localStorage.getItem('StoreID'), category: "whatsapp" }))
      await dispatch(whatsAppAccountLoad({ organisation_id: organisationId }))
    }
    dispatch(whatsAppEdit({ patientProfile: true }))
  }

  return (
    <NotificationsListingWrapper
      id='whatsapp'
      saving={saving}
      totalRecords={totalRecords}
      unread_count={unread_count}
      notifications={whatsAppNotifications}
      iconClasses={'lab la-whatsapp'}
      header={"WhatsApp"}
      logsRoute={'/add-ons/whatsapp/logs'}
      handleMessageView={handleWhatsAppPatientView}
      NotificationAdvertisement={WhatsAppAdvertisement}
      isAddonActive={whatsAppConfig?.status !== 'inactive'}
      handleNotificationLoad={handleWhatsAppNotificationLoad}
      handleNotificationUpdate={whatsAppNotificationUpdate}
    />
  )
}

const mapStateToProps = (state) => {
  const { whatsApp, voip, filter, organisation: { organisation } } = state

  return {
    whatsAppConfig: organisation.add_ons_config?.type?.whatsapp,
    whatsAppNotifications: whatsApp.notifications,
    saving: whatsApp.saving,
    storeWhatsAppNumber: whatsApp.profile_info?.phone_number,
    callPopUpStatus: voip?.call_pop_up_status,
    filters: filter.filters,
    organisationId: organisation.id,
    totalRecords: whatsApp?.meta?.pagination?.count,
    unread_count: whatsApp.meta.unread_count,
  }
}

export default connect(mapStateToProps)(WhatsAppNotificationList)
import { formatPhoneNumber } from "../../../../itrust_common/components/HelperFunctions"

export function getAddOnDefaultServiceURL(addOnsConfig) {
  let addon = Object.keys(addOnsConfig).find(addon => addOnsConfig?.[addon]?.visible)
  if (addon) {
    addon = addon.replaceAll('_', "-")
    return `/add-ons/${addon}/dashboard`
  }
  return '/dashboard/overview'
}

export const getCallStatusSymbol = (call_log) => {
  if (call_log.call_type === 'outgoing') {
    return <span><i className="las la-arrow-up rotate-45 font-size-20"></i></span>
  }
  else if (call_log.call_type === 'incoming' && call_log.status === 'no-answer') {
    return <span><i className="las la-level-up-alt rotate-45 font-size-18 text-danger"></i></span>
  }
  else {
    return <span><i className="las la-arrow-up rotate-220 text-success font-size-20"></i></span>
  }
}

export const getCallStatus = (call_log) => {
  if (call_log.status !== "completed") {
    switch (call_log.status) {
      case "no-answer":
        return call_log.call_type === 'incoming' ? <span className='text-danger'>Missed Call</span> : <span className='text-danger'>No Answer</span>
      case "canceled":
        return <span className='text-danger'>Declined Call</span>
      case "initiated":
        return <span className='text-warning'>Initiated</span>
      case null:
        return <span className='text-danger'>Missed</span>
      default: return <span className='text-danger'>{`${call_log.status}`}</span>
    }
  }
  else {
    if (call_log.call_type === 'incoming') {
      return <span className='text-dark'>Incoming Call</span>
    }
    else {
      return <span className='text-dark'>Outgoing Call</span>
    }
  }
}

export const getPatientPhoneNumber = (callLog) => {
  if (callLog.call_type === "incoming") {
    return formatPhoneNumber(callLog.from)
  }
  else {
    // outgoing
    return formatPhoneNumber(callLog.to)
  }
}

// RPA
export const isRpaVerifiedInsurance = (visionInsuranceConfig, services, insurance_name) => {
  const service = services.find((item) => item.insurance_names?.includes(insurance_name))
  return (visionInsuranceConfig?.status && visionInsuranceConfig?.status !== 'inactive' && service && service?.config?.creds?.status === 'success')
}

export const getInsuranceFromRPAServices = (services, insurance_name) => {
  const insurance = services.find((item) => item.insurance_names?.includes(insurance_name))
  return insurance
}

// AddOns Pricing
export const getAddOnPricing = (pricingObj, subscription_plan) => {
  const priceKey = pricingObj.type == "default" ? 'default_price' : 'custom_price'
  return <div className='d-flex flex-column align-items-start'>
    <h2>
      {subscription_plan === 'standard_monthly' ?
        `$${pricingObj?.[priceKey]?.monthly?.base} + $${pricingObj?.[priceKey]?.monthly?.addition}` :
        `$${pricingObj?.[priceKey]?.yearly?.base} + $${pricingObj?.[priceKey]?.yearly?.addition}`
      }
    </h2>
    <div>
      <small className='text-muted'>(1 Doctor)</small>
      <small className='text-muted ml-4'>(Per Doctor)</small>
    </div>
  </div>
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import * as $ from 'jquery';
import { examUpdate, examSecureFax, emailPrescriptionReportSend, examEditCancel, examPdfLink } from '../../../redux/actions/exam/examActions';
import ExamForm from '../exam/ExamForm';
import { tabChange } from '../../../itrust_common/redux/actions/otherActions';
import { openInNewTab } from '../../../itrust_common/components/HelperFunctions';

class EditExamModal extends Component {

  handleExamSubmit(data) {
    data = this.handleDataValidate(data)
    this.props.dispatch(examUpdate(data, {include: '*'}))
  }

  handleDataValidate(data) {
    if (data.internal_attributes?.instilled_at) {
     return this.validateInternalInstilled(data)
    }
    return data
  }

  validateInternalInstilled(data) {
    let { instilled_at } = data.internal_attributes;
    // Remove time zone abbreviation (e.g., "IST", "PST")
    instilled_at = instilled_at.replace(/\s[A-Z]{2,4}$/, '');
    // Validate and convert to ISO format (as per BE requirement) if valid
    const parsedDate = new Date(instilled_at);
    if (!isNaN(parsedDate.getTime())) {
      instilled_at = parsedDate.toISOString();
    }
    // Return updated data with modified `instilled_at`
    return {
      ...data,
      internal_attributes: {
        ...data.internal_attributes,
        instilled_at
      }
    };
  }

  handleRemoteSubmit(form) {
    this.props.dispatch(submit(form))
  }

  handleExamSecureFax(data) {
    this.props.dispatch(examSecureFax(this.props.exam.id, data))
  }

  handleEmailPrescriptionReportSend() {
    this.handleSaveBeforePrint()
    this.props.dispatch(emailPrescriptionReportSend(this.props.exam.id))
  }

  handleExamEditCancel(){
    this.props.dispatch(examEditCancel())
  }

  async handleExamPdfPrint() {
    // const { examViewModern, showOverviewModal } = this.props
    // if (examViewModern && !showOverviewModal) {
    //   this.props.dispatch(tabChange({ showOverviewModal: true }))
    //   $('#examOverviewModal').modal('show')
    // }
    // else {
    //   generatePDF(() => document.getElementById('OverviewTab'))
    // }
    const { exam } = this.props
    this.handleSaveBeforePrint()
    const url = `exams/${exam.id}/overview`
    var printLink = await this.props.dispatch(examPdfLink(exam.patient_id, { exam_print_url: url}))
    openInNewTab(printLink.value.data.filepath)
  }

  handleSaveBeforePrint(){
    const {activeTab } = this.props
    switch(activeTab){
      case 2: this.handleRemoteSubmit('examHpiForm'); break
      case 6: this.handleRemoteSubmit('examPreliminaryBinocularForm'); break
      case 7: this.handleRemoteSubmit('examRefractionContactLensForm'); break
      case 8: this.handleRemoteSubmit('examExternalInternalForm'); break
      case 9: this.handleRemoteSubmit('examAdditionalTestForm'); break
      case 11: this.handleRemoteSubmit('examFinalizePrescriptionForm'); break
      default: 
    }
  }

  componentDidUpdate() {
    if(!this.props.examEditing){
      $('#editExamForm-modal').modal('hide');  
    }
  }

  render() {
    const { exam } = this.props;
    return (
      <div className="exam-setting-modal exam-modal">
        <div className="modal fade" id="editExamForm-modal" tabIndex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
          <div className="modal-dialog m-0" role="document">
            <div className="modal-content bg-light">
              {exam.id &&
                <ExamForm 
                  onSubmit={this.handleExamSubmit.bind(this)}
                  onSecurFax={this.handleExamSecureFax.bind(this)}
                  emailPrescriptionReportSend={this.handleEmailPrescriptionReportSend.bind(this)}
                  handleExamEditCancel={this.handleExamEditCancel.bind(this)}
                  handleExamPdfPrint={this.handleExamPdfPrint.bind(this)}
                  />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { exam, other, setting: { orgConfig } } = state;
  return {
    exam: exam.exam,
    examEditing: exam.editing,
    activeTab: other.activeTab.exam,
    examViewModern: orgConfig.org_config?.exam_settings?.view_mode == 'modern',
    showOverviewModal: other.activeTab.showOverviewModal,
  };
}

export default connect(mapStateToProps)(EditExamModal);

import React from 'react';
import { change } from 'redux-form';
import { humanize } from '../../../../HelperFunctions';

export default function CopyActions({ pprops, copyToCategory, objName }) {
  const { template } = pprops;
  const fields = template.refraction_contact_lens_exam.refraction.fields;

  const attributeKeys = [
    'cycloplegic_attributes',
    'binocular_balance_attributes',
    'subjective_attributes',
    'objective_attributes',
  ];

  const attributeLabels = {
    cycloplegic_attributes: 'Cycloplegic',
    subjective_attributes: 'Subjective',
    binocular_balance_attributes: 'Binocular',
    objective_attributes: 'Objective',
  };

  const handleFinalize = () => {
    pprops.dispatch(change('examRefractionContactLensForm', `${objName}[is_finalized]`, true));
    setTimeout(() => pprops.submit(), 500);
  };

  const isAttributeVisible = (attributeKey) => {
    const attributeName = attributeKey.replace('_attributes', '');
    return fields[attributeName]?.visible === true || fields[attributeName]?.visible === 'true';
  };

  return (
    <div className="d-flex flex-row justify-content-between align-items-center mt-7">
      <div className="d-flex flex-row justify-content-end align-items-center w-100">
        {attributeKeys.map((attributeKey, index) => (
          attributeKey !== objName && isAttributeVisible(attributeKey) ? (
            <button
              key={index}
              className="btn btn-outline-primary mr-6"
              onClick={() => copyToCategory(objName, attributeKey)}
            >
              Copy To {humanize(attributeLabels[attributeKey])}
            </button>
          ) : null
        ))}
        <button type="button" className="btn btn-primary" onClick={handleFinalize}>
          Finalize
        </button>
      </div>
    </div>
  );
}
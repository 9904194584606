import { handleAPI, API_VERSION_ONE } from '../../../itrust_common/utils/apiUtils';


export function contactListsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/contact_lists`;
  const method = 'GET'
  return {
    type: 'CONTACT_LISTS_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function contactLoad(id, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/contact_lists/${id}`;
  const method = 'GET'
  return {
    type: 'CONTACT_LIST_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function contactReset() {
  return {
    type: 'CONTACT_RESET',
  }
}
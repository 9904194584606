import React from 'react';
import { formatPhoneNumber, getDefaultTextLogo } from '../../../../itrust_common/components/HelperFunctions';

let StoreInformation = props => {
  const { store, store: { address_attributes }, isPdfView } = props;
  return (
    <div className="d-flex flex-row justify-content-between overview-tab">
      <div className="text-left">
        {getDefaultTextLogo({ ...store }, `img-fluid ${isPdfView && 'store-img-width-pdf'} store-img-width`, 'store-img-width py-6')}
      </div>
      <div className="text-right">
        <h5>{store.name}</h5>
        {address_attributes &&
          [<p className="mb-1">{address_attributes.address1}, {address_attributes.address2}</p>,
          <p className="mb-1">{address_attributes.city}, {address_attributes.state} {address_attributes.zip}</p>] }
        <p className="mb-1"><span className="font-weight-bold">P-</span> {formatPhoneNumber(store.phone)}</p>
        <p className="mb-1"><span className="font-weight-bold">F-</span> {store.fax}</p>
        <p className="mb-1"><span className="font-weight-bold">E-</span> {store.email}</p>
      </div>
    </div>
  )
}


export default StoreInformation;
import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import { InputWithIcon, RadioGroup, Textarea } from '../../../../form/'
import { FormTemplateHeader, FormTemplateSectionWrapper } from '../../../../others/'
import {tabChange} from '../../../../../redux/actions/otherActions';

import GlassForm from '../visualAcuity/GlassForm'
import { connect } from 'react-redux';
import { handleClearRefractionContactLensFields } from '../../../../HelperFunctions';
import CopyActions from './CopyActions';
class Refraction extends Component {

  render() {
    const { isTemplate, template, copyToCategory, pprops } = this.props
    return (
      <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.refraction}>
        <div className="mt-7 px-5">
          <div className="d-flex justify-content-between align-items-center">
            <FormTemplateHeader
              isTemplate={isTemplate}
              fieldValues={template.refraction_contact_lens_exam.refraction}
              baseName="refraction_contact_lens_exam[refraction]"
            />

            {!isTemplate && (pprops.finalizeGlassCount > 0) && <button type="button" className={`btn btn-sm px-3 py-2 btn-success text-nowrap`} onClick={() => pprops.dispatch(tabChange({exam: 11}))}>Go To Final Rx</button>}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-md-12">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.refraction.fields.pupillary_distance}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader
                  isTemplate={isTemplate}
                  name="pupillary_distance[is_marked_complete]"
                  isToggle={pprops.initialValues && pprops.initialValues.pupillary_distance && pprops.initialValues.pupillary_distance.is_marked_complete}
                  fieldValues={template.refraction_contact_lens_exam.refraction.fields.pupillary_distance}
                  baseName="refraction_contact_lens_exam[refraction][fields][pupillary_distance]"
                  overviewName="pupillary_distance"
                  pprops={pprops}
                />
                <div className={`card-body ${(pprops.initialValues && pprops.initialValues.pupillary_distance && pprops.initialValues.pupillary_distance.is_marked_complete) || isTemplate ? '': 'd-none'}`}>
                  <div className="row">
                    {isTemplate &&
                      <RadioGroup name="refraction_contact_lens_exam[refraction][fields][pupillary_distance][show_in_finalize_prescription]" options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} label="Show in Finalize Prescription" btnWrapperClasses="ml-4" wrapperClasses="col-12 d-flex pb-4" />
                    }
                    <div className="col-4 mb-5">
                      <span>Distance</span>
                    </div>
                    <div className="col-4">
                      <span>Intermediate</span>
                    </div>
                    <div className="col-4">
                      <span>Near</span>
                    </div>
                    <Field name="pupillary_distance[od_distance]" component={InputWithIcon} title="OD" className="form-control" wrapperClasses="col-4 form-group" />
                    <Field name="pupillary_distance[od_intermediate]" component={InputWithIcon} title="OD" className="form-control" wrapperClasses="col-4 form-group" />
                    <Field name="pupillary_distance[od_near]" component={InputWithIcon} title="OD" className="form-control" wrapperClasses="col-4 form-group" />
                    <Field name="pupillary_distance[os_distance]" component={InputWithIcon} title="OS" className="form-control" wrapperClasses="col-4 form-group" />
                    <Field name="pupillary_distance[os_intermediate]" component={InputWithIcon} title="OS" className="form-control" wrapperClasses="col-4 form-group" />
                    <Field name="pupillary_distance[os_near]" component={InputWithIcon} title="OS" className="form-control" wrapperClasses="col-4 form-group" />
                    <Field name="pupillary_distance[ou_distance]" component={InputWithIcon} title="OU" className="form-control" wrapperClasses="col-4 form-group" />
                    <Field name="pupillary_distance[ou_intermediate]" component={InputWithIcon} title="OU" className="form-control" wrapperClasses="col-4 form-group" />
                    <Field name="pupillary_distance[ou_near]" component={InputWithIcon} title="OU" className="form-control" wrapperClasses="col-4 form-group" />
                  </div>
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
          
          <div className="col-xl-6 col-md-12">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.refraction.fields.intraoffice_notes}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader
                  isTemplate={isTemplate}
                  name="intraoffice_notes[is_marked_complete]"
                  isToggle={pprops.initialValues && pprops.initialValues.intraoffice_notes && pprops.initialValues.intraoffice_notes.is_marked_complete}
                  fieldValues={template.refraction_contact_lens_exam.refraction.fields.intraoffice_notes}
                  baseName="refraction_contact_lens_exam[refraction][fields][intraoffice_notes]"
                  overviewName="intraoffice_notes"
                  pprops={pprops}
                />
                <div className={`card-body ${(pprops.initialValues && pprops.initialValues.intraoffice_notes && pprops.initialValues.intraoffice_notes.is_marked_complete) || isTemplate ? '': 'd-none'}`}>
                  <div className="row">
                    <Field name="intraoffice_notes[note]" component={Textarea} rows={8} className="form-control" wrapperClasses="w-100 mb-2" />
                  </div>
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
          
          <div className="col-xl-6 col-md-12 mt-7">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.refraction.fields.objective}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader
                  isTemplate={isTemplate}
                  name="objective_attributes[is_marked_complete]"
                  isToggle={pprops.initialValues && pprops.initialValues.objective_attributes && pprops.initialValues.objective_attributes.is_marked_complete}
                  fieldValues={template.refraction_contact_lens_exam.refraction.fields.objective}
                  baseName="refraction_contact_lens_exam[refraction][fields][objective]"
                  overviewName="objective"
                  pprops={pprops}
                  clearFields={() => handleClearRefractionContactLensFields(this.props, 'objective_attributes')}
                />
                <div className={`card-body ${(pprops.initialValues && pprops.initialValues.objective_attributes && pprops.initialValues.objective_attributes.is_marked_complete) || isTemplate ? '': 'd-none'}`}>
                  <GlassForm
                    {...this.props}
                    name="objective_attributes"
                    baseName={`refraction_contact_lens_exam[refraction][fields][objective]`}
                    fieldValues={template['refraction_contact_lens_exam']['refraction']['fields']['objective']}
                  />
                  {!isTemplate && <CopyActions pprops={pprops} copyToCategory={copyToCategory} objName="objective_attributes" />}
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
          <div className="col-xl-6 col-md-12 mt-7">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.refraction.fields.subjective}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader
                  isTemplate={isTemplate}
                  name="subjective_attributes[is_marked_complete]"
                  isToggle={pprops.initialValues && pprops.initialValues.subjective_attributes && pprops.initialValues.subjective_attributes.is_marked_complete}
                  fieldValues={template.refraction_contact_lens_exam.refraction.fields.subjective}
                  baseName="refraction_contact_lens_exam[refraction][fields][subjective]"
                  overviewName="subjective"
                  pprops={pprops}
                  clearFields={() => handleClearRefractionContactLensFields(this.props, 'subjective_attributes')}
                />
                <div className={`card-body ${(pprops.initialValues && pprops.initialValues.subjective_attributes && pprops.initialValues.subjective_attributes.is_marked_complete) || isTemplate ? '': 'd-none'}`}>
                  <GlassForm
                    {...this.props}
                    name="subjective_attributes"
                    baseName={`refraction_contact_lens_exam[refraction][fields][subjective]`}
                    fieldValues={template['refraction_contact_lens_exam']['refraction']['fields']['subjective']}
                  />
                  {!isTemplate && <CopyActions pprops={pprops} copyToCategory={copyToCategory} objName="subjective_attributes" />}
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
          <div className="col-xl-6 col-md-12 mt-7">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.refraction.fields.binocular_balance}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader
                  isTemplate={isTemplate}
                  name="binocular_balance_attributes[is_marked_complete]"
                  isToggle={pprops.initialValues && pprops.initialValues.binocular_balance_attributes && pprops.initialValues.binocular_balance_attributes.is_marked_complete}
                  fieldValues={template.refraction_contact_lens_exam.refraction.fields.binocular_balance}
                  baseName="refraction_contact_lens_exam[refraction][fields][binocular_balance]"
                  overviewName="binocular_balance"
                  pprops={pprops}
                  clearFields={() => handleClearRefractionContactLensFields(this.props, 'binocular_balance_attributes')}
                />
                <div className={`card-body ${(pprops.initialValues && pprops.initialValues.binocular_balance_attributes && pprops.initialValues.binocular_balance_attributes.is_marked_complete) || isTemplate ? '': 'd-none'}`}>
                  <GlassForm
                    {...this.props}
                    name="binocular_balance_attributes"
                    baseName={`refraction_contact_lens_exam[refraction][fields][binocular_balance]`}
                    fieldValues={template['refraction_contact_lens_exam']['refraction']['fields']['binocular_balance']}
                  />
                  {!isTemplate &&
                    <div className="d-flex flex-row justify-content-between align-items-center mt-7">
                      {/* { pprops.initialValues && pprops.initialValues.binocular_balance_attributes.is_finalized &&
                        <button type="button" className={`btn btn-link text-danger px-0 text-nowrap`} onClick={() => pprops.dispatch(tabChange({exam: 10}))}>Go To Final Rx <i className="la la-arrow-right la-sm"/></button>
                      } */}
                      <div className="d-flex flex-row justify-content-end align-items-center w-100">
                        <button type="button" className={`btn btn-primary`} onClick={() => { pprops.dispatch(change('examRefractionContactLensForm', 'binocular_balance_attributes[is_finalized]', true)); setTimeout(() => pprops.submit(), 500) }}>Finalize</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
          <div className="col-xl-6 col-md-12 mt-7">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.refraction.fields.cycloplegic}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader
                  isTemplate={isTemplate}
                  name="cycloplegic_attributes[is_marked_complete]"
                  isToggle={pprops.initialValues && pprops.initialValues.cycloplegic_attributes && pprops.initialValues.cycloplegic_attributes.is_marked_complete}
                  fieldValues={template.refraction_contact_lens_exam.refraction.fields.cycloplegic}
                  baseName="refraction_contact_lens_exam[refraction][fields][cycloplegic]"
                  overviewName="cycloplegic"
                  pprops={pprops}
                  clearFields={() => handleClearRefractionContactLensFields(this.props, 'cycloplegic_attributes')}
                />
                <div className={`card-body ${(pprops.initialValues && pprops.initialValues.cycloplegic_attributes && pprops.initialValues.cycloplegic_attributes.is_marked_complete) || isTemplate ? '': 'd-none'}`}>
                  <GlassForm
                    {...this.props}
                    name="cycloplegic_attributes"
                    baseName={`refraction_contact_lens_exam[refraction][fields][cycloplegic]`}
                    fieldValues={template['refraction_contact_lens_exam']['refraction']['fields']['cycloplegic']}
                  />
                  {!isTemplate &&
                    <div className="d-flex flex-row justify-content-between align-items-center mt-7">
                      {/* { pprops.initialValues && pprops.initialValues.cycloplegic_attributes.is_finalized &&
                        <button type="button" className={`btn btn-link text-danger px-0 text-nowrap`} onClick={() => pprops.dispatch(tabChange({exam: 10}))}>Go To Final Rx <i className="la la-arrow-right la-sm"/></button>
                      } */}
                      <div className="d-flex flex-row justify-content-end align-items-center w-100">
                        <button type="button" className={`btn btn-primary`} onClick={() => { pprops.dispatch(change('examRefractionContactLensForm', 'cycloplegic_attributes[is_finalized]', true)); setTimeout(() => pprops.submit(), 500) }}>Finalize</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
        </div>
      </FormTemplateSectionWrapper>
    );
  }
}
export default connect()(Refraction)
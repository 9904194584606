import React from 'react'
import { Link } from 'react-router-dom'
import { getShortName } from '../../../../../itrust_common/components/HelperFunctions'
import { patientLoad } from '../../../../../redux/actions/patient/patientActions'
import { useDispatch } from 'react-redux'
import { layoutChange } from '../../../../../itrust_common/redux/actions/otherActions'
import DateFormatView from '../../../../../itrust_common/components/others/DateFormatView'

export default function PatientInfoHeader(props) {
  const { patient, layout, patientMessages } = props
  const dispatch = useDispatch()

  const handlePatientRedirect = () => {
    if (layout !== "default") {
      dispatch(layoutChange('default'))
    }
    dispatch(patientLoad(patient.id, { include: '*' }))
  }

  return (
    <div className='p-4 whatsapp-popup-header'>
      <div className='d-flex align-items-center'>
        {getShortName(patient.id ? patient : { name: 'A' }, 'mr-4 width-2-half height-2-half', 'mr-4 width-2-half height-2-half font-size-18 ')}
        <div className='ml-4'>
          <h5 className='text-primary'>
            {patient.id ?
              <Link className="text-primary" to={`/patients/${patient.id}/overview`} onClick={handlePatientRedirect}>
                <span className='font-size-20 m-1 text-primary'>{patient.name}</span>
              </Link> :
              <span className="text-primary">
                <span className='font-size-20 m-1 text-primary'>Anonymous</span>
              </span>
            }
          </h5>
          {patient.id ?
            <span>
              <span className='text-muted'>DOB: </span>
              {patient.date_of_birth && <DateFormatView date={patient.date_of_birth} /> | `${patient.age}`}
            </span> :
            <div>
              <span className='text-secondary'>From:</span>
              <span className='ml-2'>{patientMessages[0].from}</span>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

import Toastr from "../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  contact_lists: [],
  contact_list: {},
  saving: false,
}
let changes = null;

export const contactReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {
    case 'CONTACT_LISTS_LOAD_PENDING':
    case 'CONTACT_LIST_LOAD_PENDING':
      return { ...state, saving: true };

    case 'CONTACT_LISTS_LOAD_REJECTED':
    case 'CONTACT_LIST_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'CONTACT_LISTS_LOAD_FULFILLED':
    case 'CONTACT_LIST_LOAD_FULFILLED':
      changes = { ...response, saving: false };
      return { ...state, ...changes };

    case 'CONTACT_RESET':
      return { ...state, contact_list: {} };

    default:
      return state;
  }
}
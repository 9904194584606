import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
import { checkIsVisible, dateFormat, getTemplateSupportedField } from '../../../itrust_common/components/HelperFunctions';
import ExamTabsContent from './ExamTabsContent';
import ExamTabs from './ExamTabs';
import ExamHeaderForm from './edit/ExamHeaderForm';
import ExamHeader from './ExamHeader';
import { medicalRxsLoad } from '../../../redux/actions/medicalRxActions';
import Overview from './Overview';
import * as $ from 'jquery';
import { appointmentLoad } from '../../../redux/actions/appointmentActions';
import Alert from '../../../itrust_common/components/Alert';
import { tabChange } from '../../../itrust_common/redux/actions/otherActions';
import { Tabs, getFirstTabElementId } from './CommonFunctions';
import AutoSaveExamFormLogic from './AutoSaveExamFormLogic';

let activeSection = '';
function ExamForm(props) {
  const { store, activeTab, onSubmit, handleExamEditCancel, handleExamPdfPrint, examViewModern, onSecurFax, emailPrescriptionReportSend, exam, showExternalInternalForm, exam: { patient_attributes }, currentUser, showOverviewModal, dateFormatType } = props;
  const [accordionToggle, setAccordionToggle] = useState(true)
  const [lastScrollPos, setLastScrollPos] = useState(0)
  const [disabledScrollEvent, setDisabledScrollEvent] = useState(false)
  const dispatch = useDispatch()
  const firstTab = getTemplateSupportedField(exam.template)

  useEffect(() => {
    handleMedicalRxsLoad({ examination_id: exam.id })
  }, [])

  const handleMedicalRxsLoad = (filters) => {
    dispatch(medicalRxsLoad(exam.patient_id, filters))
  }

  const handleScroll = (event) => {
    if (event.currentTarget.scrollTop === 0) {
      setLastScrollPos(event.currentTarget.scrollTop)
      setAccordionToggle(true)
    } else if (lastScrollPos < event.currentTarget.scrollTop) {
      setLastScrollPos(event.currentTarget.scrollTop)
      setAccordionToggle(false)
    }
    if (examViewModern) {
      const isAtBottom = (event.currentTarget.scrollTop + event.currentTarget.clientHeight) === event.currentTarget.scrollHeight;
      handleTabActiveOnScroll()
      if (checkIsVisible(exam.template.attached_documents?.visible) && isAtBottom && activeTab !== 12) {
        // fixes for attached_documents tab has less length
        dispatch(tabChange({ exam: 12 }));
      }
    }
  }

  const handleScrollToExamModules = () => {
    // first Tab scroll issue fixes on scroll, as header cutting
    const element = document.getElementById(getFirstTabElementId(firstTab));
    const container = document.getElementById('editExamForm-modal');
    if (element && container) {
      const elementPosition = element.offsetTop;
      setTimeout(() => container.scrollTo({ top: elementPosition - 300, behavior: 'smooth', }), 100);
    }
  }

  const handleFinalizedUpdate = () => {
    onSubmit({ id: exam.id, status: "finalized" })
    if (exam.appointment_id) setTimeout(() => {
      dispatch(appointmentLoad(exam.appointment_id, { include: ['patient', 'staff', 'procedures', 'examination', 'order'] }))
    }, 1000)
  }

  const handleExamFinalize = () => {
    Alert.confirm(<div>You're finalizing exams for <strong>{patient_attributes.name}</strong>. Exam will become read-only.</div>,
      (res) => res && handleFinalizedUpdate(onSubmit, exam))
  }

  const handleTabActiveOnScroll = () => {
    if (disabledScrollEvent) return; // Disable this scroll event for onTab click, as it create issue when tab have less content
    Tabs.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        // Check if section is in the upper half of the viewport
        const isInUpperHalf = rect.top >= 0 && rect.top < window.innerHeight / 2;
        // Check if section is in the lower half of the viewport
        const isInLowerHalf = rect.bottom > window.innerHeight / 2 && rect.bottom <= window.innerHeight;
        // If the section is in either the top or bottom half of the viewport, mark it as active
        if (isInUpperHalf || isInLowerHalf) {
          activeSection = section.id;
        }
      }
    });
    dispatch(tabChange({ exam: Tabs.find(item => item.id === activeSection)?.tab }));
  }

  return (
    <div>
      <Helmet>
        <title>{`${exam.patient_attributes.name} ${dateFormat(exam.exam_date, dateFormatType)} ${store.name}`}</title>
      </Helmet>
      <div className="fixed-container-modal px-5 pt-5 bg-light patient-exam-form-fixed">
        <ExamHeader onSubmit={onSubmit} handleExamPdfPrint={handleExamPdfPrint} handleExamEditCancel={handleExamEditCancel} accordionToggle={accordionToggle} setAccordionToggle={setAccordionToggle} handleExamFinalize={handleExamFinalize} />
        <ExamHeaderForm accordionToggle={accordionToggle} currentUserRole={currentUser.role} onSubmit={onSubmit} patient={patient_attributes} defaultVisible={accordionToggle} handleScrollToExamModules={handleScrollToExamModules} />
        <ExamTabs handleScrollToExamModules={handleScrollToExamModules} setDisabledScrollEvent={setDisabledScrollEvent} disabledScrollEvent={disabledScrollEvent} />
      </div>
      <ExamTabsContent onSubmit={onSubmit} handleScroll={handleScroll} onSecurFax={onSecurFax} emailPrescriptionReportSend={emailPrescriptionReportSend} accordionToggle={accordionToggle} showExternalInternalForm={showExternalInternalForm} />
      {examViewModern && <ExamOverviewModal exam={exam} store={store} patient={patient_attributes} handleExamPdfPrint={handleExamPdfPrint} handleExamFinalize={handleExamFinalize} showOverviewModal={showOverviewModal} dispatch={dispatch} currentUser={currentUser} />}
      <AutoSaveExamFormLogic handleScrollToExamModules={handleScrollToExamModules} />
    </div>
  )
}

const ExamOverviewModal = (props) => {
  const { exam, store, patient, handleExamPdfPrint, handleExamFinalize, showOverviewModal, dispatch, currentUser } = props

  const handleOverviewModalClose = () => {
    if ((currentUser.role === 'non_admin' && exam.status === "finalized" && currentUser.id !== exam.staff_attributes.id)) {
      $('#editExamForm-modal').modal('hide')
      dispatch(tabChange({ showExternalInternalForm: false, showOverviewModal: false }))
    }
    dispatch(tabChange({ showOverviewModal: false }))
    $('#examOverviewModal').modal('hide')
  }

  return (
    <div className="modal my-4" id="examOverviewModal" data-backdrop="static">
      <div className="modal-dialog exam-overview-modal">
        <div className="modal-content border-0 shadow-lg rounded">
          <div className="modal-header p-6 align-items-center border-bottom border-bottom-2 border-light bg-white z-index-2 position-sticky">
            <h5 className="text-dark">Overview</h5>
            <div className="d-flex flex-row">
              {exam.status === "finalized" ?
                <button type="button" className="btn btn-success mr-5">Finalized</button> :
                <button type="button" className="btn btn-outline-primary mr-5" onClick={handleExamFinalize}>Finalize Exam</button>
              }
              <button type="button" className="btn btn-outline-primary mr-5" onClick={handleExamPdfPrint}>Print</button>
              <button type="button" className="btn btn-outline-primary" onClick={handleOverviewModalClose}>Close</button>
            </div>
          </div>
          <div className="modal-body">
            {showOverviewModal && <Overview exam={exam} store={store} patient={patient} showExternalInternalForm={true} />}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { auth, exam: { exam }, organisation, setting: { orgConfig }, other } = state;

  return {
    exam: exam,
    organisation: organisation.organisation,
    store: exam.store_attributes,
    currentUser: auth.user,
    activeTab: other.activeTab.exam,
    showExternalInternalForm: other.activeTab.showExternalInternalForm,
    showOverviewModal: other.activeTab.showOverviewModal,
    examViewModern: orgConfig.org_config?.exam_settings.view_mode == 'modern',
    dateFormatType: organisation.organisation.preferences?.date_format,
  }
}

export default connect(mapStateToProps)(ExamForm);
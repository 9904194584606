import React from 'react'
import EyeMedClaimGenerate from '../../services/eyemed/claimForm/EyeMedClaimGenerate'
import Rpa1500ClaimGenerate from './Rpa1500ClaimGenerate'

export default function RpaClaimFormGenerate(props) {
  const { selectedInsuranceName, services, handleGenerateClaimForm } = props

  const getServiceName = (serviceName) => {
    const service = services.find((item) => item.config.service == serviceName)
    return service.insurance_names.includes(selectedInsuranceName) ? selectedInsuranceName : ''

  }

  switch (selectedInsuranceName) {
    case getServiceName('eyemed'):
      return <EyeMedClaimGenerate pprops={{ ...props, rpaClaimFormGenerate: handleGenerateClaimForm }} />

    default:
      return <Rpa1500ClaimGenerate rpaClaimFormGenerate={handleGenerateClaimForm} />
  }
}

import React, { Component } from 'react';
import { reduxForm, FieldArray, change } from 'redux-form';
import { connect } from 'react-redux';
import * as $ from 'jquery';
import LensForm from './contactLens/LensForm';
import ImportWrapper from './import/ImportWrapper';
import { orderCloseModal } from '../../../../redux/actions/order/orderActions';
import DuplicateLensOrGlassButton from '../../buttons/DuplicateLensOrGlassButton';
import CurrencyTypeView from '../../../../itrust_common/components/others/CurrencyTypeView';

class RenderContactLenses extends Component {
  importFinalLens(member, index, data) {
    const { pprops } = this.props
    pprops.dispatch(change('orderContactLensForm', `${member}[od_sphere]`, data.od_sphere))
    pprops.dispatch(change('orderContactLensForm', `${member}[od_cylinder]`, data.od_cylinder))
    pprops.dispatch(change('orderContactLensForm', `${member}[od_axis]`, data.od_axis))
    pprops.dispatch(change('orderContactLensForm', `${member}[od_add]`, data.od_add))
    pprops.dispatch(change('orderContactLensForm', `${member}[od_curve]`, data.od_curve))
    pprops.dispatch(change('orderContactLensForm', `${member}[od_diameter]`, data.od_diameter))
    pprops.dispatch(change('orderContactLensForm', `${member}[od_contact_lens_id]`, data.od_contact_lens_id))
    pprops.dispatch(change('orderContactLensForm', `${member}[od_contact_lens_attributes]`, data.od_contact_lens_attributes))
    pprops.dispatch(change('orderContactLensForm', `${member}[os_sphere]`, data.os_sphere))
    pprops.dispatch(change('orderContactLensForm', `${member}[os_cylinder]`, data.os_cylinder))
    pprops.dispatch(change('orderContactLensForm', `${member}[os_axis]`, data.os_axis))
    pprops.dispatch(change('orderContactLensForm', `${member}[os_add]`, data.os_add))
    pprops.dispatch(change('orderContactLensForm', `${member}[os_curve]`, data.os_curve))
    pprops.dispatch(change('orderContactLensForm', `${member}[os_diameter]`, data.os_diameter))
    pprops.dispatch(change('orderContactLensForm', `${member}[os_contact_lens_id]`, data.os_contact_lens_id))
    pprops.dispatch(change('orderContactLensForm', `${member}[os_contact_lens_attributes]`, data.os_contact_lens_attributes))
    $(`#examFinalLens-Modal${index}`).modal('hide')
  }


  render() {
    const { fields, pprops, order } = this.props
    return (
      <div className="row">
        <div className="col-12 mb-7">
          <div className={`d-flex justify-content-between align-items-center ${order.status !== "finalized" && 'mt-6'}`}>
            <h5>Contact Lens Order</h5>
            <div className="d-flex align-items-center">
              <ImportWrapper order={order} importName="contact_lens" />
              <button type="button" className="btn btn-primary ml-5" onClick={() => { fields.push({}); setTimeout(() => pprops.submit(), 500) }}>
                Add New
              </button>
            </div>
          </div>
        </div>
        {fields.map((member, index, members) => (
          <div key={index} className="col-12 mb-7">
            <div className="bg-white shadow-sm rounded">
              <div className="row mx-0">
                <div className="col-12 p-5 border-bottom border-bottom-2 border-light">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5>Contact Lens Order #{index+1}</h5>
                    <div className="d-flex align-items-center">
                      <DuplicateLensOrGlassButton orderId={order.id} lensOrGlassName='contact_lens' id={eval(`order.${member}?.id`)}>
                        <button className={`btn btn-outline-primary mx-3`} aria-disabled="true">Duplicate</button>
                      </DuplicateLensOrGlassButton>
                      {
                        members.get(index).is_on_hold ?
                        <button type="button" className="btn btn-warning mx-3"
                          onClick={() => { pprops.dispatch(change('orderContactLensForm', `${member}[is_on_hold]`, false)); setTimeout(() => pprops.submit(), 500) }}
                        >
                          Unhold Order
                        </button>
                        :
                        <button type="button" className="btn btn-outline-primary mx-3"
                          onClick={() => { pprops.dispatch(change('orderContactLensForm', `${member}[is_on_hold]`, true)); setTimeout(() => pprops.submit(), 500) }}
                        >
                          Hold Order
                        </button>
                      }
                      { order.exam_examination_id &&
                        <button onClick={()=> $(`#examFinalLens-Modal${index}`).modal()} type="button" className="btn btn-outline-primary mx-3" >
                          Order Prescription
                        </button>
                      }
                      <button type="button" className="btn btn-outline-danger ml-3" 
                        onClick={() => { pprops.dispatch(change('orderContactLensForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}>
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
                {members.get(index).id &&
                  <LensForm
                    {...this.props}
                    currentContactLens={ members.get(index) }
                    member={member}
                  />
                }
              </div>
              {this.renderExamLensModal( member, index )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderExamLensModal( member, index ){
    const { exam } = this.props
    return(
      <div className="inner-modal">
        <div className="modal fade" id={`examFinalLens-Modal${index}`} tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content border-0 rounded shadow-lg">
              <div className="modal-header border-bottom border-bottom-2 border-light">
                <h5>Finalized Lens Prescriptions</h5>
                <button type="button" className="btn btn-outline-primary" onClick={()=> $(`#examFinalLens-Modal${index}`).modal('hide')}>
                  Close
                </button>
              </div>
              <div className="modal-body px-0 py-5">
                <div className="overview-table">
                  <table className="table table-hover shadow-none">
                    <thead className="text-dark border-bottom border-bottom-2">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Eye</th>
                        <th scope="col">Brand</th>
                        <th scope="col">Type</th>
                        <th scope="col">SPH</th>
                        <th scope="col">CYL</th>
                        <th scope="col">Axis</th>
                        <th scope="col">Add</th>
                        <th scope="col">Curve</th>
                        <th scope="col">DIA</th>
                        <th scope="col">Price</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      exam.id && exam.finalized_lenses_attributes.map((finalizedLens, index)=>
                        [<tr className="text-dark border-bottom border-light border-bottom-2">
                          <td>{index+1}</td>
                          <td>OD</td>
                          <td>{finalizedLens.od_contact_lens_attributes && finalizedLens.od_contact_lens_attributes.brand_name}</td>
                          <td>{finalizedLens.od_contact_lens_attributes && finalizedLens.od_contact_lens_attributes.lens_type}</td>
                          <td>{finalizedLens.od_sphere}</td>
                          <td>{finalizedLens.od_cylinder}</td>
                          <td>{finalizedLens.od_axis}</td>
                          <td>{finalizedLens.od_add}</td>
                          <td>{finalizedLens.od_curve}</td>
                          <td>{finalizedLens.od_diameter}</td>
                          <td>{finalizedLens.od_contact_lens_attributes && <CurrencyTypeView amount={finalizedLens.od_contact_lens_attributes.unit_price} />}</td>
                          <td>
                            <button onClick={()=> this.importFinalLens(member, index, finalizedLens)} className="btn px-3 py-2 btn-outline-primary">
                              Import
                            </button>
                          </td>
                        </tr>,

                        <tr className="text-dark border-bottom border-bottom-2">
                          <td></td>
                          <td>OS</td>
                          <td>{finalizedLens.os_contact_lens_attributes && finalizedLens.os_contact_lens_attributes.brand_name}</td>
                          <td>{finalizedLens.os_contact_lens_attributes && finalizedLens.os_contact_lens_attributes.lens_type}</td>
                          <td>{finalizedLens.os_sphere}</td>
                          <td>{finalizedLens.os_cylinder}</td>
                          <td>{finalizedLens.os_axis}</td>
                          <td>{finalizedLens.os_add}</td>
                          <td>{finalizedLens.os_curve}</td>
                          <td>{finalizedLens.os_diameter}</td>
                          <td>{finalizedLens.os_contact_lens_attributes && <CurrencyTypeView amount={finalizedLens.os_contact_lens_attributes.unit_price} />}</td>
                        </tr>]
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


class ContactLensForm extends Component {

  componentWillUnmount(){
    if(this.props.dirty){
      this.props.handleSubmit();
      this.props.dispatch(orderCloseModal(false))
    }
  }

  render(){
    const { handleSubmit, order, exam, onSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <FieldArray name="contact_lenses_attributes"
          props = {{
            order: order,
            exam: exam,
            onSubmit: onSubmit,
            pprops: this.props
          }}
          component={RenderContactLenses} />
      </form>
    )
  }
}

ContactLensForm = reduxForm({
  form: 'orderContactLensForm',
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if(props.dirty) {
      dispatch(orderCloseModal(true))
    }
  }
})(ContactLensForm)

ContactLensForm = connect(state=> {
  const { order: { order }, exam: { exam } } = state
  return {
    exam: exam,
    order: order,
    initialValues: {
      id: order.id,
      contact_lenses_attributes: order.contact_lenses_attributes
    }
  }
})(ContactLensForm)

export default ContactLensForm;

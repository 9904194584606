import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'
import sle from '../../../../../itrust_common/assets/static/images/sle-tablet-img.png'
import cornea from '../../../../../itrust_common/assets/static/images/cornea-tablet-img.png';
import GonioscopyImage from '../../../../../itrust_common/assets/static/images/gonioscopy.png'
import CanvasDrawing from '../../../../../itrust_common/components/others/CanvasDrawing';
import { buildHashString } from '../../../../../itrust_common/components/HelperFunctions';

let External = props => {
  const { fieldsValues, template, isPdfView} = props;
  const externalRow = template.fields.ou_tear_film.visible === "true" || template.fields.ou_cornea.visible === "true" ||
                      template.fields.ou_sclera.visible == "true" || template.fields.ou_iris.visible === "true" ||
                      template.fields.ou_lens.visible === "true" || template.fields.ou_palpebral_conj.visible === "true" ||
                      template.fields.ou_ac.visible === "true" || template.fields.ou_angle.visible === "true" ||
                      template.fields.ou_lids_lashes.visible === "true" || template.fields.ou_bulbar_conj.visible === "true" ? ["od", "os", "ou"] : ["od", "os"]
  const sleCanvasWidth = isPdfView ? 341 : 410;
  const sleCanvasHeight = isPdfView ? 121 : 145;
  const corneaCanvasWidth = isPdfView ? 340 : 335;
  const corneaCanvasHeight = isPdfView ? 107 : 108;
  return (
    <div className="row">
      {
        template.fields.sle["visible"] === 'true' &&
        <div className={`col-lg-6 ${isPdfView && 'pdf-responsive-col-6'} pdf-nobreak`}>
          <DisplayField
            isVisible={template.fields.sle.visible}
            label={template.fields.sle.custom_label}
            className="pt-4"
          />
          <CanvasDrawing
            isTemplate={true}
            wrapperClasses="bg-light rounded text-center pt-3 pb-5 mt-5"
            className="mx-auto"
            canvasRef="sleCanvasRef"
            canvasWidth={sleCanvasWidth}
            canvasHeight={sleCanvasHeight}
            imgSrc={sle}
            disabled={true}
            isMarkCompleted={fieldsValues.is_marked_complete}
            defaultData={fieldsValues.eye_draw_data ? fieldsValues.eye_draw_data : { height: "145px", width: "410px", lines: [] }}
          />
        </div>
      }
      {
        template.fields.cornea["visible"] === 'true' &&
        <div className={`col-4 ${isPdfView && 'pdf-responsive-col-6'} pdf-nobreak`}>
          <DisplayField
            isVisible={true}
            label={"Cornea"}
            className="pt-4"
          />
          <CanvasDrawing
            isTemplate={true}
            wrapperClasses="bg-light rounded text-center pt-3 pb-5 mt-5"
            className="mx-auto"
            canvasRef="corneaCanvasRef"
            canvasWidth={corneaCanvasWidth}
            canvasHeight={corneaCanvasHeight}
            imgSrc={cornea}
            disabled={true}
            isMarkCompleted={fieldsValues.is_marked_complete}
            defaultData={fieldsValues.cornea && fieldsValues.cornea.corneal_exam_draw_data ? fieldsValues.cornea.corneal_exam_draw_data : { height: "108px", width: "335px", lines: [] }}
          />

          { fieldsValues.cornea && fieldsValues.cornea.diseases && <DisplayField
            isVisible={true}
            label={"Cornea Diseases"}
            value={fieldsValues.cornea && fieldsValues.cornea.diseases && buildHashString(fieldsValues.cornea.diseases)}
            className="py-4 d-inline-block pr-3"
          /> }

          { fieldsValues.cornea && fieldsValues.cornea.note && <DisplayField
            isVisible={true}
            label={"Cornea Notes"}
            value={fieldsValues.cornea && fieldsValues.cornea.note}
            className="py-4 d-inline-block pl-3"
          /> }
        </div>
      }

      {
        template.fields.gonioscopy["visible"] === 'true' &&
        <div className="col-4">
        { ((fieldsValues.gonioscopy_exam.od_inferior || fieldsValues.gonioscopy_exam.od_nasal || fieldsValues.gonioscopy_exam.od_temporal || fieldsValues.gonioscopy_exam.od_superior)
          ||
          (fieldsValues.gonioscopy_exam.os_inferior || fieldsValues.gonioscopy_exam.os_nasal || fieldsValues.gonioscopy_exam.os_temporal || fieldsValues.gonioscopy_exam.os_superior))
          &&
          <DisplayField
            isVisible={true}
            label={"Gonioscopy"}
            className="py-4"
          /> }
          <div className="row">
          {(fieldsValues.gonioscopy_exam.od_inferior || fieldsValues.gonioscopy_exam.od_nasal || fieldsValues.gonioscopy_exam.od_temporal || fieldsValues.gonioscopy_exam.od_superior) &&
            <div className="col-6 text-center">
              <div className="bg-light py-3">
                <h6>OD</h6>
                <div className="external-gonioscopy-test position-relative d-inline-block my-3">
                  <img src={GonioscopyImage} className="img-fluid pr-3" style={{width: '9rem'}}/>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.od_inferior}
                    className="overview-top-range"/>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.od_nasal}
                    className="overview-left-range"/>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.od_temporal}
                    className="overview-right-range"/>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.od_superior}
                    className="overview-bottom-range"/>

                </div>
                <div className="d-flex justify-content-center ">
                  <h6>Pigmentation</h6>
                  <h6>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.od_pigmentation}
                    className="pl-2"/>
                  </h6>
                </div>
              </div>
            </div>}

            {(fieldsValues.gonioscopy_exam.os_inferior || fieldsValues.gonioscopy_exam.os_nasal || fieldsValues.gonioscopy_exam.os_temporal || fieldsValues.gonioscopy_exam.os_superior) &&
            <div className="col-6 text-center">
              <div className="bg-light py-3">
                <h6>OS</h6>
                <div className="external-gonioscopy-test position-relative d-inline-block my-3">
                  <img src={GonioscopyImage} className="img-fluid" style={{width: '9rem'}}/>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.os_inferior}
                    className="overview-top-range"/>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.os_nasal}
                    className="overview-left-range"/>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.os_temporal}
                    className="overview-right-range"/>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.os_superior}
                    className="overview-bottom-range"/>
                </div>
                <div className="d-flex justify-content-center">
                  <h6>Pigmentation</h6>
                  <h6>
                  <DisplayField
                    isVisible={true}
                    value={fieldsValues.gonioscopy_exam.os_pigmentation}
                    className="pl-2"/>
                  </h6>
                </div>
              </div>
            </div> }
          </div>

          { fieldsValues.gonioscopy_exam && fieldsValues.gonioscopy_exam.note && <DisplayField
            isVisible={true}
            label={"Gonioscopy Notes"}
            value={fieldsValues.gonioscopy_exam && fieldsValues.gonioscopy_exam.note}
            className="py-4"
          /> }
        </div>
      }

      <div className="col-12 pt-4">
        <div className="overview-table mb-3 text-nowrap overflow-auto">
          <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
            <thead className="text-dark border border-muted">
              <tr className="text-center">
                <th scope="col"></th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_tear_film.visible}
                    label={template.fields.od_tear_film.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_cornea.visible}
                    label={template.fields.od_cornea.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_sclera.visible}
                    label={template.fields.od_sclera.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_iris.visible}
                    label={template.fields.od_iris.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_lens.visible}
                    label={template.fields.od_lens.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_palpebral_conj.visible}
                    label={template.fields.od_palpebral_conj.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_ac.visible}
                    label={template.fields.od_ac.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_angle.visible}
                    label={template.fields.od_angle.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_lids_lashes.visible}
                    label={template.fields.od_lids_lashes.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_bulbar_conj.visible}
                    label={template.fields.od_bulbar_conj.custom_label}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {externalRow.map(type =>
                <tr className="text-center" style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                  <td>
                    <h6>{type.toUpperCase()}</h6>
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.tear_film[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.cornea[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.sclera[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.iris[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.lens[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.palpebral_conj[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.ac[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.angle[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.lids_lashes[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.bulbar_conj[type]}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}


export default External;

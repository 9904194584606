import React, { Component } from 'react';
import { connect } from 'react-redux';
import CurrencyTypeView from '../../../../../itrust_common/components/others/CurrencyTypeView';

class Procedure extends Component{
  getProcedureTotal(procedures){
    let total = 0
    procedures.forEach(procedure => {
      return total = total + procedure.subtotal
    })
    return total
  }

  render(){
    const { procedures, order } = this.props
    return [
      <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
        <td colspan="6"><h6>Exam Procedure</h6></td>
        <td><h6 className="text-danger">{order.cash_procedures_discount ? <CurrencyTypeView amount={order.cash_procedures_discount} /> : ''}</h6></td>
        <td><h6>{this.getProcedureTotal(procedures) ? <CurrencyTypeView name={this.getProcedureTotal(procedures)} /> : ''}</h6></td>
      </tr>,
      procedures.map((procedure, index) => (
        <tr key={`procedure-${index}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td colSpan="2">{index + 1}. {procedure.description}</td>
          <td className="pl-8">{procedure.unit_price ? <CurrencyTypeView amount={procedure.unit_price} /> : ''}</td>
          <td>{procedure.copay ? <CurrencyTypeView amount={procedure.copay} /> : ''}</td>
          <td>{(procedure.cash_discount || procedure.percentage_discount) ? <CurrencyTypeView amount={procedure.discount_type === "cash" ? procedure.cash_discount : procedure.percentage_discount} /> : ''}</td>
          <td colSpan="3">{procedure.subtotal ? <CurrencyTypeView name={procedure.subtotal} /> : ''}</td>
        </tr>
      ))
    ]
  }
}

export default connect()(Procedure);
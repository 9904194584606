import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link, withRouter } from 'react-router-dom';

class Banner extends Component {

  redirectToPayment() {
    var route = window.location.pathname;
    if (!(route === `/payment/current-plan/current-plan` || route === `/payment/contact-support` || route === `/payment/plan-pricing` || route === '/payment/current-plan/manage-license'))
      return <Redirect exact from="*" to={`/payment/current-plan/current-plan`} />
  }

  renderMessage() {
    const { organisation, subscriptionDetails, currentUser } = this.props
    if (currentUser && currentUser.type === "Patient") document.body.className = ""
    if (organisation.subscription_plan != "enterprise" && (currentUser && currentUser.type !== "Patient")) {
      if ((organisation.status === "unpaid" || organisation.status === "inactive") &&
        subscriptionDetails.billing_attempt.status == "failed") {
        document.body.classList.add('banner-align')
        return (
          <p className="mb-0 py-1">
            {subscriptionDetails.billing_attempt && subscriptionDetails.billing_attempt.count >= 4 && this.redirectToPayment()}
            There was an unsuccessfully attempt made with the card of file ending in {subscriptionDetails.payments_method.card_ending_number} for your service fee. Update the card on file to continue hassle free access.
            <Link to={`/payment/current-plan/current-plan`}> Add Now</Link>
          </p>
        )
      }
      else if (organisation.status === "inactive" && subscriptionDetails.payments_method.is_card_attached == "false" && organisation.other_details?.renew_trial_status) {
        document.body.classList.add('banner-align')
        return (
          <p className="mb-0 py-1">
            {this.redirectToPayment()}
            🚀 Schedule a quick 15-minute Zoom training to unlock all the features and maximize your iTRUST experience.
            <a className='text-primary pointer' href="https://www.itrust.io/demo" target='_blank'> Book Your Session Now </a>👈
          </p>
        )
      }
      else if (organisation.status === "inactive" && subscriptionDetails.payments_method.is_card_attached == "false" && !organisation.other_details?.renew_trial_status) {
        document.body.classList.add('banner-align')
        return (
          <p className="mb-0 py-1">
            {this.redirectToPayment()}
            Your trial has expired! Please add your card to continue hassle free access. Or if you need more time with your trial email us at <a href="mailto: support@itrust.io">support@itrust.io</a>.
          </p>
        )
      } else if (organisation.status === "active" && subscriptionDetails && subscriptionDetails.payments_method.is_card_attached == "false") {
        document.body.classList.add('banner-align')
        return (
          <p className="mb-0 py-1">
            {this.redirectToPayment()}
            To continue hassle free access add your card.   <Link className="text-primary ml-2" to={`/payment/current-plan/current-plan`}> *Add Now*</Link>
          </p>
        )
      } else if ((organisation.status === "trial" || organisation.trial_expiry_days > 0) && subscriptionDetails.payments_method.is_card_attached == "false" && organisation.other_details.show_trial_banner) {
        document.body.classList.add('banner-align')
        return (
          <p className="mb-0 py-1">
            😎 Active your account simply by adding your card details and continue hassle free access.  Days left in trial: {organisation.trial_expiry_days}
            <Link className="text-primary ml-2" to={`/payment/current-plan/current-plan`}> Add Now</Link>
          </p>
        )
      } else if (organisation.is_card_expiring === true && organisation.status == "active") {
        document.body.classList.add('banner-align')
        return (
          <p className="mb-0 py-1">
            Your card will expire on {`${subscriptionDetails.payments_method.expiry_month}/${subscriptionDetails.payments_method.expiry_year}`} please update your card to continue hassle free access.
            <Link to={`/payment/current-plan/current-plan`}> Change Now</Link>
          </p>
        )
      } else if (organisation.subscription_plan === 'read_only' && organisation.status == "active") {
        document.body.classList.add('banner-align')
        return (
          <p className="mb-0 py-1">
            You are subscribed to read only mode and no write actions can be preformed. To upgrade your plan email us at contact@itrust.io
          </p>
        )
      }
      else if (organisation.other_details?.instant_access && organisation.status === "trial" && organisation.other_details.show_trial_banner) {
        document.body.classList.add('banner-align')
        return (
          <p className="mb-0 py-1">
            🚀 Schedule a quick 15-minute Zoom training to unlock all the features and maximize your iTRUST experience.
            <a className='text-primary pointer' href="https://www.itrust.io/demo" target='_blank'> Book Your Session Now </a>👈
          </p>
        )
      }
    }
  }

  render() {
    return (
      <div className="main-content-banner">
        <div className="alert alert-danger text-center rounded-0 border-0 py-0 mb-0" role="alert">
          {this.renderMessage()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { organisation, auth } = state;
  return {
    organisation: organisation.organisation,
    subscriptionDetails: organisation.organisation.subscription_details,
    currentUser: auth.user,
  }
}

export default connect(mapStateToProps)(withRouter(Banner));

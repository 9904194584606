import React from 'react'
import { numberToCurrency } from '../HelperFunctions'
import { useCurrentTypeHook } from './helperHooks/useCurrentTypeHook'

export default function CurrencyTypeView(props) {
  const CurrentType = useCurrentTypeHook()
  const { amount } = props

  const Locale = {
    USD: 'en-US',
    ZAR: 'en-ZA'
  }

  return (
    <>{CurrentType ? numberToCurrency(amount, CurrentType.code, Locale[CurrentType.code]) : ''}</>
  )
}

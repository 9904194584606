import React, { Component } from 'react';
import { Field } from 'redux-form';

import { Input, RadioGroup } from '../../../../itrust_common/components/form';
import { connect } from 'react-redux';

class DiscountField extends Component {
  constructor(props){
    super(props)
    this.state={discountType: props.defaultType}
  }
  render() {
    const { cashName, percentageName, discountTypeName, prependClasses, currency } = this.props;
    return (
      <div className="input-group flex-nowrap">
        <div className="input-group-prepend">
          <span className={`input-group-text ${prependClasses}`}>
            <RadioGroup onChangeHook={(val)=> {this.setState({discountType: val})}}
              ref={discountTypeName}
              name={discountTypeName}
              radioLabelClasses="px-3"
              options={[{ label: currency.symbol, value: 'cash' }, { label: ' % ', value: 'percentage' }]} />
          </span>
        </div>
        {this.state.discountType === 'cash' ?
          <Field ref={cashName} name={cashName} component={Input}  className="form-control" />
          :
          <Field ref={percentageName} name={percentageName} component={Input}  className="form-control" />
        }
      </div>
    );
  }
}

DiscountField = connect(state => {
  const { organisation: { organisation } } = state
  return {
    currency: organisation.preferences.currency
  }
})(DiscountField)

export default DiscountField;
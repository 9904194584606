import React, { Component } from 'react';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import { connect } from 'react-redux';

import { AutoComplete } from '../../../../itrust_common/components/others';
import { InputWithIcon } from '../../../../itrust_common/components/form';

import DiscountField from './DiscountField';
import { orderCloseModal } from '../../../../redux/actions/order/orderActions';
import CurrencyTypeView from '../../../../itrust_common/components/others/CurrencyTypeView';

class RenderMiscItems extends React.Component {
  render() {
    const { fields, order, pprops, currency } = this.props
    return (
      <tbody>
        {fields.map((member, index, members) => (
          <tr key={`miscItem-${index}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
            <td>{members.get(index).description}</td>
            <td>{members.get(index).exam_code}</td>
            <td>
              <Field name={`${member}[unit_price]`} type="number" component={InputWithIcon} title={currency.symbol} className="form-control" wrapperClasses="d-flex w-75" />
            </td>
            <td>
              <Field name={`${member}[copay]`} component={InputWithIcon} title={currency.symbol} className="form-control" wrapperClasses="d-flex w-75" />
            </td>
            <td>
              <div className="d-flex w-75">
                <DiscountField
                  cashName={`${member}[cash_discount]`}
                  percentageName={`${member}[percentage_discount]`}
                  discountTypeName={`${member}[discount_type]`}
                  defaultType={members.get(index).discount_type}
                />
              </div>
            </td>
            <td>
              <CurrencyTypeView amount={members.get(index).subtotal} />
            </td>
            <td>
              <a className="badge badge-pill badge-primary" onClick={() => { pprops.dispatch(change('orderMiscItemForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}>
                <i className="la la-trash"></i>
              </a>
            </td>
          </tr>
        ))}
        {(fields.length > 0) && <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td >
          </td>
          <td colSpan="3" className="text-right">
            <h6>Overall Discount</h6>
          </td>
          <td>
            <div className="d-flex w-75">
              <DiscountField
                cashName="cash_misc_items_discount"
                percentageName="percentage_misc_items_discount"
                discountTypeName="misc_items_discount_type"
                defaultType={order.misc_items_discount_type}
              />
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>}
      </tbody>
    );
  }
}


class MiscItemForm extends Component {

  componentWillUnmount() {
    if (this.props.dirty) {
      this.props.handleSubmit();
      this.props.dispatch(orderCloseModal(false))
    }
  }

  getStoreIdForSearch() {
    const { orderStoreId } = this.props
    let StoreId = localStorage.getItem('StoreID')
    if (!StoreId && StoreId == null) {
      return orderStoreId
    }
    return StoreId;
  }

  render() {
    const { handleSubmit, order, onSubmit, currency } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="card border-0 shadow-sm px-5">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <label>Add MiscItem</label>
                <AutoComplete
                  onSelect={(id) => { onSubmit({ id: order.id, misc_items_attributes: [{ settings_misc_order_item_id: id, store_id: '' }] }) }} klass="Settings::MiscOrderItem" textField="addons" valueField="id" placeholder="Type in to search by misc. item name" wrapperClasses="col-4 px-0" additionalFilters={{ store_inventory: this.getStoreIdForSearch() }}
                />
              </div>
              <div className="col-12 mt-7">
                <div className="overview-table px-2">
                  <table className="table table-hover shadow-none">
                    <thead className="text-dark border-bottom bg-light border-white border-bottom-2">
                      <tr>
                        <th scope="col">Description</th>
                        <th scope="col">Code</th>
                        <th scope="col">Unit Price (in {currency.symbol})</th>
                        <th scope="col">Copay</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Subtotal</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <FieldArray name="misc_items_attributes"
                      props={{
                        order: order,
                        onSubmit: onSubmit,
                        pprops: this.props
                      }}
                      component={RenderMiscItems} />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

MiscItemForm = reduxForm({
  form: 'orderMiscItemForm',
  enableReinitialize: true,
  // destroyOnUnmount: false
  onChange: (values, dispatch, props) => {
    if (props.dirty) {
      dispatch(orderCloseModal(true))
    }
  }
})(MiscItemForm)

MiscItemForm = connect(state => {
  const { order } = state.order
  const { organisation: { organisation } } = state
  return {
    currency: organisation.preferences.currency
    ,
    order: order,
    orderStoreId: order.store_id,
    initialValues: {
      id: order.id,
      percentage_misc_items_discount: order.percentage_misc_items_discount,
      cash_misc_items_discount: order.cash_misc_items_discount,
      misc_items_discount_type: order.misc_items_discount_type,
      misc_items_attributes: order.misc_items_attributes
    }
  }
})(MiscItemForm)

RenderMiscItems = connect(state => {
  const { organisation: { organisation } } = state
  return {
    currency: organisation.preferences.currency
    ,
  }
})(RenderMiscItems)

export default MiscItemForm;
import React, { useState } from 'react'
import PaymentSuccessGif from '../../../../../assets/static/images/payment-success.gif'
import PaymentFailedGif from '../../../../../assets/static/images/payment-failed.gif'
import { devicePaymentLogDelete, devicePaymentStatusUpdate } from '../../../../../redux/actions/order/orderPaymentActions'
import * as $ from 'jquery';
import { numberToCurrency } from '../../../../../itrust_common/components/HelperFunctions'
import { itrustPayPaymentSettle } from '../../../../../redux/actions/addOns/itrustPay/itrustPayPaymentLogsActions';
import Alert from '../../../../../itrust_common/components/Alert';
import { orderLoad } from '../../../../../redux/actions/order/orderActions';
import CurrencyTypeView from '../../../../../itrust_common/components/others/CurrencyTypeView';

export default function PaymentInProgress(props) {
  const { devicePayment, dispatch, deviceLog, orderId } = props
  const [cancelPayment, setIsCancelPayment] = useState(false)

  const handleCancelPayment = async (deviceLogId) => {
    await dispatch(devicePaymentLogDelete({ id: deviceLogId }))
    localStorage.removeItem('deviceLogId');
  }

  const handleClosePaymentSuccess = () => {
    dispatch(devicePaymentStatusUpdate({ active: false, status: "" }));
    $("#orderFinalizeForm-modal").modal("hide");
  }

  const handleLogsSettle = () => {
    Alert.confirm(<span>Are you sure you want to settle {numberToCurrency(deviceLog.requested_amount)}</span>, (res) => {
      if (res) {
        dispatch(itrustPayPaymentSettle({ order_id: orderId, id: deviceLog.id, payment_date: new Date(), amount_to_settle: deviceLog.requested_amount })).then(() => {
          $("#orderFinalizeForm-modal").modal('hide')
          dispatch(orderLoad(orderId, { include: '*' }))
        })
      }
    })
  }

  return (
    <div className='text-center position-absolute payment-inprogress bg-white rounded border py-8 z-index-2 d-flex align-items-center justify-content-center'>
      <div className='payment-wrap'>
        {(devicePayment.status === 'cancelled') && <div className='font-size-30 close-icon' onClick={handleClosePaymentSuccess}><i className="las la-times"></i></div>}
        {devicePayment.status === 'in_progress' &&
          <>
            <div className={`${cancelPayment && 'd-none'}`}>
              <div className='d-flex flex-column align-items-center'>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <h4 className='mt-6'>Terminal Payment In Progress</h4>
                <p className='mb-3 text-muted'>Please wait don’t close this window</p>
                <h5 className='text-primary my-3 font-size-30'>
                  Payment Amount: <CurrencyTypeView amount={deviceLog.requested_amount} />
                </h5>
                <button className='btn btn-primary px-8 mt-8' onClick={() => setIsCancelPayment(true)}>Cancel Payment</button>
              </div>
            </div>
            {cancelPayment && <div className='d-flex flex-column algin-items-center'>
              <span className='text-danger font-size-100'>
                <i className="las la-exclamation-triangle"></i>
              </span>
              <h3>Cancel Payment?</h3>
              <p className='mb-3'>Are you sure to cancel this payment! </p>
              <button className='btn btn-primary w-50 mx-auto mt-5' onClick={() => handleCancelPayment(deviceLog.id)}>Cancel Payment</button>
              <button className='btn btn-outline-primary w-50 mx-auto mt-5' onClick={() => setIsCancelPayment(false)}>Not Now</button>
            </div>}
          </>
        }
        {(devicePayment.status === 'completed' || devicePayment.status === 'cancelled') && <div>
          <img src={PaymentSuccessGif} loop={false} />
          <h1 className='mt-8'>Payment Successful!</h1>
        </div>}
        {devicePayment.status === 'cancelled' && <div>
          <p className='w-75 mx-auto py-5'>Payment is mismatched as received:
            <strong><CurrencyTypeView amount={devicePayment.amount} /> </strong>
            whereas requested amount was <strong><CurrencyTypeView amount={deviceLog.requested_amount} /></strong>
            .</p>
          <button className="btn btn-lg btn-primary px-8" onClick={handleLogsSettle}>Settle Amount</button>
        </div>}
        {devicePayment.status === 'failed' && <div>
          <img src={PaymentFailedGif} loop={false} className='width-11' />
          <h4 className='w-75 mx-auto pb-3'>Payment Failed</h4>
          <p className='text-muted'>{devicePayment?.error_message}</p>
          <button className="btn btn-outline-primary mt-4 px-8" onClick={() => dispatch(devicePaymentStatusUpdate({ active: false, status: '' }))}>Close</button>
        </div>}
      </div>
    </div>
  )
}
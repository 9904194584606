import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form'
import * as $ from 'jquery' 

import { examPdfLink } from '../../../redux/actions/exam/examActions';
import { openInNewTab } from '../../../itrust_common/components/HelperFunctions';

import { referralsLoad, referralLoad, referralEdit, referralEditCancel, referralDelete, referralCreate, referralUpdate } from '../../../redux/actions/referralActions'
import { tabChange } from '../../../itrust_common/redux/actions/otherActions'

import Listing from './ReferralComponents/Listing';
import Show from './ReferralComponents/Show';
import ReferralForm from '../../../components/authorised/referral/ReferralForm';
import Alert from '../../../itrust_common/components/Alert';
import { SaveButton } from '../../../itrust_common/components/others';
import Moment from 'moment';

class Referral extends Component{
  constructor(props){
    super(props)
    this.handleReferralsLoad()
  }

  handleReferralsLoad(){
    this.props.dispatch(referralsLoad(this.props.exam.id))
  }


  async handleReferralPdfPrint() {
    const { exam, referralId } = this.props
    const url = `exams/${exam.id}/referrals/${referralId}`
    var printLink = await this.props.dispatch(examPdfLink(exam.patient_id, { referral_print_url: url }))
    openInNewTab(printLink.value.data.filepath)
    // generatePDF(() => document.getElementById('referralPrint'))
  }

  handleReferralEdit(id){
    if (id !== 'New') this.props.dispatch(referralLoad(this.props.exam.id, id));
    this.props.dispatch(referralEdit(id));
    this.activeTabChange(2)
  }

  handleReferralLoad(id){
    this.props.dispatch(referralLoad(this.props.exam.id, id));
    this.activeTabChange(3)
  }

  handleReferralEditCancel(){
    this.props.dispatch(referralEditCancel());
    this.activeTabChange(1)
  }

  handleReferralDelete(id){
    Alert.deleteConfirm("Are you sure? You want to delete this record.", 
    (response)=>{
      if(response)
        this.props.dispatch(referralDelete(this.props.exam.id, id))
    })
  }
  handleRemoteSubmit(){
    this.props.dispatch(submit('referralForm'))
  }
  async handleReferralSubmit(data) {
    const id = data.id || '';
    if (id === '') {
      await this.props.dispatch(referralCreate(this.props.exam.id, data))
    } else {
      this.props.dispatch(referralUpdate(this.props.exam.id, data))
    }
    this.activeTabChange(1)

  }

  async handleResendReferral(referralId) {
    var res = await this.props.dispatch(referralLoad(this.props.exam.id, referralId));
    const referralData = { ...res.value.data.referral, referral_date: Moment(new Date()).format("YYYY/MM/DD"), }
    this.props.dispatch(referralCreate(this.props.exam.id, referralData))
  }

  componentDidUpdate() {
    if (!this.props.referralEditing){
      this.props.dispatch(reset('referralForm'))
    }
  }

  activeTabChange(val){
    this.props.dispatch(tabChange({referral: val}))
  }

  render(){
    const { activeTab, isPdfView } = this.props;
    return (
      <div className={`referral-modal ${isPdfView && 'table-bg-white'}`}>
        <div className="modal" id="referralModal" data-backdrop="static">
          <div className={`modal-dialog ${activeTab === 3 ? 'referral-preview-modal-dialog': 'modal-lg'}`}>
            <div className="modal-content border-0 shadow-lg rounded">
              <div className="modal-header p-6 align-items-center border-bottom border-bottom-2 border-light">
                <h5 className="text-dark" >Referrals</h5>
                <div className="d-flex flex-row">
                  {this.renderHeader(activeTab)}
                </div>
              </div>
              {activeTab === 1 && 
                <Listing 
                  handleReferralResend={this.handleResendReferral.bind(this)}
                  handleReferralEdit={this.handleReferralEdit.bind(this)} 
                  handleReferralDelete={this.handleReferralDelete.bind(this)}
                  handleReferralLoad={this.handleReferralLoad.bind(this)}/> }
              {activeTab === 2 && 
                <ReferralForm onSubmit={this.handleReferralSubmit.bind(this)}/> }
              {activeTab === 3 && 
                <Show isPdfView={isPdfView} /> }
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderHeader(activeTab){
    const { saving } = this.props;

    switch(activeTab){
      case 2: return [
        <button type="button" className="btn btn-outline-primary mr-7" onClick={this.handleReferralEditCancel.bind(this)}>Cancel</button>,
        <SaveButton saving={saving} className="btn btn-primary" label="Save & Send" handleOnClick={this.handleRemoteSubmit.bind(this)}/>
      ]
      case 3: return [
        <button type="button" className="btn btn-outline-primary mr-7"
          onClick={this.handleReferralPdfPrint.bind(this)}>
          Print
        </button>,
        <button type="button" className="btn btn-outline-primary" onClick={this.handleReferralEditCancel.bind(this)}>Close</button>
      ]
      default : return [
        <button type="button" className="btn btn-outline-primary mr-7" onClick={()=> $('#referralModal').modal('hide')}>Close</button>,
        <button type="button" className="btn btn-primary" onClick={this.handleReferralEdit.bind(this, 'New')}>Create New</button>
      ]
    }
  }
}

const mapStateToProps = state => {
  const {referral, other} = state;
  return {
    referralId: referral.referral.id,
    referrals: referral.referrals.data,
    referralEditing: referral.editing,
    saving: referral.saving,
    activeTab: other.activeTab.referral,
  }
}

export default connect(mapStateToProps)(Referral);
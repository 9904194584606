import React, { useEffect } from 'react'
import EyeMedClaimForm from '../../services/eyemed/claimForm/form/EyeMedClaimForm';
import { connect, useDispatch } from 'react-redux';
import { visionInsuranceClaimFormEdit, visionInsuranceClaimFormTemplateLoad } from '../../../../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceClaimFormAction';

function RpaClaimForm(props) {
  const { order, visionInsuranceClaimFormTemplate } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(visionInsuranceClaimFormTemplateLoad())
    dispatch(visionInsuranceClaimFormEdit({ activeTab: 'first', activeModule: '' }))
  }, [])

  const getClaimFormTemplate = () => {
    return visionInsuranceClaimFormTemplate.find(item => item.service == order.vision_insurance_service?.service)
  }

  switch (order.vision_insurance_service?.service) {
    case 'eyemed':
      return <EyeMedClaimForm claimFormTemplate={getClaimFormTemplate()} />

    default:
      return ''
  }
}

const mapStateToProps = (state) => {
  const { order: { order }, visionInsuranceClaimForm: { visionInsuranceClaimFormTemplate } } = state
  return {
    // services,
    order,
    visionInsuranceClaimFormTemplate,
  }
}

export default connect(mapStateToProps)(RpaClaimForm);
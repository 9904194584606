import Toastr from "../../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  saving: false,
  editing: false,
  tab_add_on: {},
  rpa_tab_settings: {
    procedure: {
      records: [],
    },
    staff: {
      records: [],
      mapped_records: []
    },
    store: {
      records: [],
    },
  },
}

let changes = null;
let tabAddOn = null;
let tabMapperStatus = false;

export const tabReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)

  const getSortRecords = (data) => {
    const sortArr = Object.groupBy(data, ({ mapper_type }) => mapper_type);
    const mappedStaffRecords = sortArr.staff?.filter(setting => setting.mapper_id?.length)
    return {
      procedure: {
        records: sortArr.procedure || []
      },
      staff: {
        records: sortArr.staff || [],
        mapped_records: mappedStaffRecords || []
      },
      store: {
        records: sortArr.store || [],
      },
    }
  }

  switch (action.type) {

    case 'TAB_SETTING_UPDATE_PENDING':
    case 'TAB_ADD_ON_UPDATE_PENDING':
      return { ...state, saving: true };

    case 'TAB_ADD_ON_LOAD_REJECTED':
    case 'TAB_ADD_ON_UPDATE_REJECTED':
    case 'TAB_SETTINGS_LOAD_REJECTED':
    case 'TAB_SETTING_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'TAB_ADD_ON_LOAD_FULFILLED':
      tabAddOn = response.add_ons?.[0]
      tabMapperStatus = false
      if (tabAddOn) {
        tabMapperStatus = Object.values(tabAddOn.config.mapper_status)?.find(item => item == "pending")
      }
      changes = { saving: false, tab_add_on: tabAddOn ?? {}, tabMapperStatus: tabMapperStatus }
      return { ...state, ...changes };

    case 'TAB_ADD_ON_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { saving: false, tab_add_on: response.add_on ?? {} }
      return { ...state, ...changes };

    case 'TAB_SETTINGS_LOAD_FULFILLED':
      changes = {
        saving: false,
        rpa_tab_settings: getSortRecords(response.rpa_tab_settings),
      }
      return { ...state, ...changes };

    case 'TAB_SETTING_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { saving: false, };
      return { ...state, ...changes };

    case 'TAB_EDIT':
      changes = { editing: { ...state.editing, ...action.data } }
      return { ...state, ...changes };

    case 'TAB_EDIT_RESET':
      changes = { editing: false }
      return { ...state, ...changes };

    default:
      return state
  }
}
import React from 'react'
import { connect } from 'react-redux'
import { Input } from '../../../../../itrust_common/components/form'
import { Field, change, formValueSelector, reduxForm, submit } from 'redux-form'
import { humanize, isNonEmptyString } from '../../../../../itrust_common/components/HelperFunctions'

function WhatsAppChatForm(props) {
  const { handleSubmit, dispatch, patient, is_chat_initiated, chatStatus, messageBody } = props

  const initiateChatMessage = `Hi ${patient.name?? 'there'},
You have a pending message from our office. To initiated the conversation please reply Y.`

  const initiateWhatsAppChat = () => {
    dispatch(change('WhatsAppChatForm', 'body_values', `${patient.name?? 'there'}`))
    dispatch(change('WhatsAppChatForm', 'body', initiateChatMessage))
    setTimeout(() => dispatch(submit('WhatsAppChatForm')), 700);
  }

  const requestToChatToolTip = 'WhatsApp requires businesses to request to chat if there has not been a chat message with 24 hours. Once the client responds to your chat request you can send additional messages. Please note that automated and campaign messages will still be sent without a chat request.'

  return (
    <form onSubmit={handleSubmit}>
      <div className={`whatsapp-chat-form p-5 text-center`}>
        {chatStatus == 'chat' &&
          <div>
            <div className='d-flex align-items-center'>
              <Field component={Input} name="body" className='form-control' wrapperClasses="w-100 pr-0 text-left" placeholder='Type your message...' rows={2} />
              <div className='pl-4'>
                <button type='submit' className={`btn btn-primary btn-sm font-size-24 ${!messageBody && 'disabled'}`}><i class="las la-paper-plane"></i></button>
              </div>
            </div>
          </div>
        }
        {chatStatus == 'time' && <div>
          <button type='button' className='btn initiate-chat-btn text-white w-100'>{humanize(is_chat_initiated.time)}</button>
          <p className='mb-0 mt-3 font-size-14'>Send the other message after 24 hours or when the user replies.</p>
        </div>}
        {chatStatus == 'initiate' && <div className='whatsapp-request-chat'>
          <button type='submit' className='btn initiate-chat-btn text-white w-100 custom-tooltip' data-title={requestToChatToolTip} onClick={initiateWhatsAppChat}>Request To Chat</button></div>}
      </div>
    </form>
  )
}

const whatsAppChatForm = formValueSelector('WhatsAppChatForm')

WhatsAppChatForm = reduxForm({
  form: 'WhatsAppChatForm',
})(WhatsAppChatForm);

const mapStateToProps = (state) => {
  const { whatsApp, currentStore } = state
  const messageBody = whatsAppChatForm(state, 'body')

  return {
    messageBody: isNonEmptyString(messageBody),
    initialValues: {
      to: whatsApp.patient.cell_phone,
      from: whatsApp.profile_info?.phone_number,
      whatsapp_phone_number_id: whatsApp.profile_info?.phone_number_id,
      message_type: "business",
      status: "initiate",
      category: "manual",
      store_id: currentStore.store.id,
      patient_id: whatsApp.patient.id
    }
  }
}

export default connect(mapStateToProps)(WhatsAppChatForm)
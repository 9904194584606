import React from 'react'
import { useCurrentTypeHook } from './helperHooks/useCurrentTypeHook'

export default function CurrencyIconsView(props) {
  const { className } = props
  const CurrentType = useCurrentTypeHook()

  return (
    <>
      {CurrentType?.code && <>
        {CurrentType?.code === "USD" ?
          <i className={`la la-sm la-dollar-sign ${className}`} /> :
          <span className={`${className}`}>{CurrentType?.symbol}</span>
        }
      </>}
    </>
  )
}

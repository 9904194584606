import React from 'react'
import PatientMessageWrapper from './PatientMessageWrapper'
import { connect } from 'react-redux';
import TwoWayAdvertisement from '../../../../twoWayMessage/TwoWayAdvertisement';
import PatientProfileAddOns from '../../../../other/patient/PatientProfileAddOns';
import PatientInfoHeader from '../../../../other/patient/PatientInfoHeader';

function PatientMessagePopUp(props) {
  const { twoWayMessageConfig, voipMinimizeView, contact, patientMessages } = props

  return (
    <>
      {twoWayMessageConfig?.status && twoWayMessageConfig?.status !== 'inactive' ?
        <>
          <PatientProfileAddOns
            btnClasses="text-primary bg-transparent border-0 font-size-20 py-0"
            wrapperClasses="justify-content-between p-3"
            isPopUp={true}
            type='two_way_message'
            patient={contact}
          />
          {voipMinimizeView ?
            <PatientInfoHeader patient={contact} patientMessages={patientMessages} /> :
            <PatientMessageWrapper />}
        </> :
        <>
          <PatientProfileAddOns
            btnClasses="text-primary bg-transparent border-0 font-size-20 py-0"
            wrapperClasses="justify-content-between p-3"
            isPopUp={true}
            type='two_way_message'
            hideAddOnsBtn={true}
            hideMinimizeBtn={true}
          />
          <TwoWayAdvertisement isPopupScreen={true} />
        </>
      }
    </>
  )
}

const mapStateToProps = state => {
  const { voip, organisation: { organisation }, contact, patientMessage } = state;

  return {
    twoWayMessageConfig: organisation?.add_ons_config?.type?.two_way_message,
    voipMinimizeView: voip.minimizeView,
    contact: contact.contact_list,
    patientMessages: patientMessage.patient_messages,
  }
}

export default connect(mapStateToProps)(PatientMessagePopUp);
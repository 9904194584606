import React from 'react'
import { itrustPayPaymentSettle } from '../../../../../redux/actions/addOns/itrustPay/itrustPayPaymentLogsActions'
import { getCardBrandImg, numberToCurrency } from '../../../../../itrust_common/components/HelperFunctions'
import Alert from '../../../../../itrust_common/components/Alert'
import * as $ from 'jquery';
import { orderLoad } from '../../../../../redux/actions/order/orderActions';
import DateFormatView from '../../../../../itrust_common/components/others/DateFormatView';
import CurrencyTypeView from '../../../../../itrust_common/components/others/CurrencyTypeView';

export default function DeviceLogsListing(props) {
  const { yesterdayPaymentLogs, dispatch, orderId, amount } = props

  const handleLogsSettle = (log) => {
    Alert.confirm(<span>Are you sure you want to associate this payment of {numberToCurrency(amount)}</span>, (res) => {
      if (res) {
        dispatch(itrustPayPaymentSettle({ order_id: orderId, id: log.id, payment_date: new Date(), amount_to_settle: amount })).then(() => {
          $("#orderFinalizeForm-modal").modal('hide')
          dispatch(orderLoad(orderId, { include: '*' }))
        })
      }
    })
  }

  return (
    <div className='mx-height-15 overflow-auto'>
      <h6 className='float-left pb-4'>Unassociated Payments</h6>
      <table className="overview-table table table-bordered shadow-none text-nowrap">
        <thead className="thead-light thead-0">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Total Charged</th>
            <th scope="col">Open to settle</th>
            <th scope="col">Charge ID</th>
            <th scope="col">Card no.</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {yesterdayPaymentLogs.length ? yesterdayPaymentLogs.map((log, index) => <tr key={log.id}>
            <td>
              <DateFormatView date={log.created_at} />
            </td>
            <td>
              <CurrencyTypeView amount={log.amount} />
            </td>
            <td>
              <CurrencyTypeView amount={log.unsettled_amount} />
            </td>
            <td>{log.payment_details.payment_intent_id}</td>
            <td>{getCardBrandImg(log.card_type, 'mx-2')}{log.last_4_digit}</td>
            <td>
              <span className='text-primary pointer' onClick={() => handleLogsSettle(log)}>Settle</span>
            </td>
          </tr>) :
            <tr>
              <td colspan={7}>No Record Found</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}

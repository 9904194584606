import React from 'react';
import DisplayField from '../../../../itrust_common/components/others/DisplayField'
import { humanize, dateFormat, formatPhoneNumber, getCustomLabel } from '../../../../itrust_common/components/HelperFunctions';
import { connect } from 'react-redux';
import DateFormatView from '../../../../itrust_common/components/others/DateFormatView';

let PatientInformation = props => {
  const { patient, isPdfView, referralDisplayFields, visionInsuranceTemplate, medicalInsuranceTemplate } = props;
  const patientAddress = patient.home_address &&
    [<p className={`mb-1 text-dark ${isPdfView ? 'font-size-12' : 'font-size-14'}`} >{patient.home_address.address1}, {patient.home_address.address2}</p>,
    <p className={`mb-1 text-dark ${isPdfView ? 'font-size-12' : 'font-size-14'}`}>{patient.home_address.city}, {patient.home_address.state} {patient.home_address.zip}</p>]
  return (
    <div className="row">
      <div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3">Patient Information</h6>
        </div>
      </div>
      <DisplayField
        isVisible={true}
        label={"Name:"}
        value={patient.name}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={true}
        label={"Date Of Birth:"}
        value={isPdfView ? dateFormat(patient.date_of_birth, 'D MMM YYYY') : <DateFormatView date={patient.date_of_birth} />}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={true}
        label={"Age:"}
        value={patient.age && patient.age.toString()}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={true}
        label={"Gender:"}
        value={humanize(patient.gender)}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={true}
        label={"Email:"}
        value={patient.email}
        className={`${isPdfView ? 'col-4' : 'col-2'} py-4`}
      />
      <DisplayField
        isVisible={true}
        label={"Phone:"}
        value={formatPhoneNumber(patient.cell_phone)}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={true}
        label={visionInsuranceTemplate && getCustomLabel(visionInsuranceTemplate, 'insurance')}
        value={patient.patient_vision_insurance && patient.patient_vision_insurance.insurance_name}
        className="col-2 py-4"
      />
      {(isPdfView || referralDisplayFields?.display_fields?.patient_information?.vision_number) && <DisplayField
        isVisible={true}
        label={visionInsuranceTemplate && getCustomLabel(visionInsuranceTemplate, 'member_id')}
        value={patient.patient_vision_insurance?.member_id}
        className="col-2 py-4"
      />}
      <DisplayField
        isVisible={true}
        label={medicalInsuranceTemplate && getCustomLabel(medicalInsuranceTemplate, 'insurance')}
        value={patient.patient_medical_insurance && patient.patient_medical_insurance.insurance_name}
        className="col-2 py-4"
      />
      {(isPdfView || referralDisplayFields?.display_fields?.patient_information?.medical_number) && <DisplayField
        isVisible={true}
        label={medicalInsuranceTemplate && getCustomLabel(medicalInsuranceTemplate, 'member_id')}
        value={patient.patient_medical_insurance?.member_id}
        className="col-2 py-4"
      />}
      {patient.occupation && <DisplayField
        isVisible={true}
        label={"Occupation:"}
        value={patient.occupation}
        className="col-2 py-4"
      />}
      {(isPdfView || referralDisplayFields?.display_fields?.patient_information?.ssn) && <DisplayField
        isVisible={true}
        label={"SSN:"}
        value={patient.ssn}
        className="col-2 py-4"
      />}
      <DisplayField
        isVisible={true}
        label={"Address:"}
        value={patientAddress}
        className="col-4 py-4"
      />
    </div>
  )
}

const mapStateToProps = state => {
  const { setting: { patientReport, patientTemplate: { patient_template } } } = state
  const patientInsuranceData = patient_template?.personal_information?.insurance_detail.fields
  return {
    visionInsuranceTemplate: patientInsuranceData?.vision_insurance.fields,
    medicalInsuranceTemplate: patientInsuranceData?.medical_insurance.fields,
    referralDisplayFields: patientReport?.patient_reports[0]?.category == "referral" ? patientReport?.patient_reports[0] : patientReport?.patient_reports[1],
  }
}

export default connect(mapStateToProps)(PatientInformation);